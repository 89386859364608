import React from 'react'


import Layout from '@/components/Layout'



const TrainingenPage = () => {

  return (

    <Layout
      pageTitle="Trainingen"
      heroTitle="Trainingen"
      heroSubTitle=""
      heroTagLine="Elke woensdag &amp; vrijdag"
      heroImage=""
      metaDescription="Trainingen, groepen, lidgeld..."
    >



      <div className="row pb-5">
<table className="table table-bordered table-hover table-responsive-md">
<thead>
<tr>
<th>Groep</th>
<th>Maandag</th>
<th>Woensdag</th>
<th>Vrijdag</th>
</tr>
</thead>
<tbody>
<tr>
<td>
Juniores (6–9jaar)
</td>
<td className="text-center">
/
</td>
<td colSpan="2" className="text-center">
18.00u tot 18.50u
</td>
</tr>
<tr>
<td>
Juniores (10–13jaar)
</td>
<td className="text-center">/</td>
<td colSpan="2" className="text-center">
19.00u tot 20.00u
</td>
</tr>
<tr>
<td>
Seniores (vanaf 14 jaar)
</td>
<td className="text-center">/</td>
<td colSpan="2" className="text-center">
20.00 tot 21.30u
</td>
</tr>
<tr>
<td>
<strong>Nieuw</strong> zelfverdediging voor volwassenen
</td>
<td className="text-center">/</td>
<td colSpan="2" className="text-center">
20.00 tot 21.30u
</td>
</tr>
</tbody>
</table>

      </div>



      <div className="row justify-content-center pb-3">

        <div className="col-sm col-md-6 mb-5">
          <p>
            Alle trainingen gaan door in onze eigen dojo (oefenzaal), gelegen aan de
            Jan De Windtstraat 59 te Aalst.
          </p>
          <p>
            Wil je eens proberen of het iets voor je is? Je kan altijd een proefles komen meedoen!
          </p>
          <p>
            Naast de wekelijkse trainingen is er voor
            alle leden de mogelijkheid om deel te nemen aan binnen- en buitenlandse stages,
            competities, clubfeest, …
          </p>
          <p>
            Er zijn geen trainingen voor de seniores tijdens de maand juli; voor de juniores is er
            geen tijdens de schoolvakanties.
          </p>
          <p>
            De juniores worden ingedeeld in twee leeftijdsgroepen, met daarin een onderverdeling volgens niveau. Elke niveaugroep heeft zijn eigen lesgevers,
            zodat er een optimale en verantwoorde
            begeleiding is.
          </p>
        </div>

      </div>

      <div className="row justify-content-center pb-3">

        <div className="col-sm col-md-6 mb-5">
          <h2 className="h4 mb-2">Lidgeld</h2>

          <dl className="row mb-0">
            <dt className="col-sm-8">Juniores</dt>
            <dd className="col-sm-4">150,00 EUR per jaar</dd>
            <dt className="col-sm-8">Seniores</dt>
            <dd className="col-sm-4">175,00 EUR per jaar</dd>
          </dl>

          Aansluiting bij de federatie en verzekering is inbegrepen.
        </div>

        <div className="col-sm col-md-6 mb-5">
          <h2 className="h4 mb-2">Kledij</h2>
          <p>
            Voor de eerste lessen volstaat een trainingspak of losse kledij.
            Bij aansluiting is een witte jiu-jitsugi (oefenpak) noodzakelijk; dit kan in de club bekomen
            worden. Richtprijs: afhankelijk van de maat, tussen de 35 en 50 euro.
          </p>
        </div>
      </div>

    </Layout>
  )
}

export default TrainingenPage
